import React, { useState, useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';
import ReactLoading from 'react-loading';

export default function Loading() {
  return (
    <>
      <InnerDiv>
        <ReactLoading type="bars" height={'10%'} width={'10%'} />
      </InnerDiv>
    </>
  );
}

const InnerDiv = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgb(96 200 255 / 50%);
  z-index: 100000;
  left: 0;
  top: 0;
`;
