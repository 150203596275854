import React from 'react';
import styled, { css } from 'styled-components';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Router from 'next/router';

// サイドメニュー
const Sidemenu = ({ className }) => {
  return (
    <SidemenuArea className={className}>
      <nav css="margin-bottom:20px;">
        <SidemenuUl>
          <SidemenuLi>
            <Link passHref href="/categories/jigyoyotei-kanrengyojiyotei">
              <SidemenuNavA>
                <FontAwesomeIcon
                  icon={['fas', 'calendar-alt']}
                  css="margin-right: 2.4rem;color:#0070D2;"
                />
                事業予定・関連行事予定
              </SidemenuNavA>
            </Link>
          </SidemenuLi>
          <SidemenuLi>
            <Link passHref href="/categories/toubanhyou">
              <SidemenuNavA>
                <FontAwesomeIcon
                  icon={['fas', 'table']}
                  css="margin-right: 2.4rem;color:#0070D2;"
                />
                当番表
              </SidemenuNavA>
            </Link>
          </SidemenuLi>
          <SidemenuLi>
            <Link passHref href="/categories/syomuhoukoku">
              <SidemenuNavA>
                <FontAwesomeIcon
                  icon={['far', 'file-alt']}
                  css="margin-right: 2.4rem;color:#0070D2;"
                />
                庶務報告
              </SidemenuNavA>
            </Link>
          </SidemenuLi>
          <SidemenuLi>
            <Link passHref href="/categories/yamatoayaseshikaihou">
              <SidemenuNavA>
                <FontAwesomeIcon
                  icon={['far', 'newspaper']}
                  css="margin-right: 2.4rem;color:#0070D2;"
                />
                大和綾瀬歯会報
              </SidemenuNavA>
            </Link>
          </SidemenuLi>
          <SidemenuLi>
            <Link passHref href="/categories/yakuin-meibo">
              <SidemenuNavA>
                <FontAwesomeIcon
                  icon={['fas', 'clipboard-list']}
                  css="margin-right: 2.4rem;color:#0070D2;"
                />
                役員名簿
              </SidemenuNavA>
            </Link>
          </SidemenuLi>
          <SidemenuLi>
            <Link passHref href="/categories/kaiin-meibo">
              <SidemenuNavA>
                <FontAwesomeIcon
                  icon={['fas', 'clipboard-list']}
                  css="margin-right: 2.4rem;color:#0070D2;"
                />
                会員名簿
              </SidemenuNavA>
            </Link>
          </SidemenuLi>
          <SidemenuLi>
            <Link passHref href="/categories/teikan-kisoku">
              <SidemenuNavA href="">
                <FontAwesomeIcon
                  icon={['fas', 'file-alt']}
                  css="margin-right: 2.4rem;color:#0070D2;"
                />
                定款・規則
              </SidemenuNavA>
            </Link>
          </SidemenuLi>
          <SidemenuLi>
            <Link passHref href="/categories/kakusyu-shinseisyo">
              <SidemenuNavA>
                <FontAwesomeIcon
                  icon={['fas', 'file-alt']}
                  css="margin-right: 2.4rem;color:#0070D2;"
                />
                各種申請書
              </SidemenuNavA>
            </Link>
          </SidemenuLi>
        </SidemenuUl>
      </nav>
      <Button1 onClick={() => window.open('https://shimin.yamatoshikaishikai.or.jp/', '_blank')}>
        市民サイトへ
        <FontAwesomeIcon
          icon={['fas', 'chevron-right']}
          css="position: absolute;top: 50%;right: 8%;transform: translateY(-50%);"
        />
      </Button1>
      <Button1 onClick={() => window.open('https://riji.yamatoshikaishikai.or.jp/', '_blank')}>
        理事会サイトへ
        <FontAwesomeIcon
          icon={['fas', 'chevron-right']}
          css="position: absolute;top: 50%;right: 8%;transform: translateY(-50%);"
        />
      </Button1>
      <Button2
        onClick={() =>
          window.open('https://www.yamatoshikaishikai.or.jp/kaiin/index.shtml', '_blank')
        }
      >
        旧会員サイトへ
        <br />
        (旧ID・PASSで閲覧可)
        <FontAwesomeIcon
          icon={['fas', 'chevron-right']}
          css="position: absolute;top: 50%;right: 8%;transform: translateY(-50%);"
        />
      </Button2>
      <SidemenuUl>
        <BannerLi>
          <Link passHref href="https://www.jda.or.jp/member/index.html">
            <a target="_blank">
              <Banner src="/images/banner/lm_jda.jpg" />
            </a>
          </Link>
        </BannerLi>
        <BannerLi>
          <Link passHref href="https://amari-akira.com/">
            <a target="_blank">
              <Banner src="/images/banner/lm_amari.jpg" />
            </a>
          </Link>
        </BannerLi>
        <BannerLi>
          <Link passHref href="http://www.kdrenmei2011.jp/">
            <a target="_blank">
              <Banner src="/images/banner/lm_kanagawa_renmei.jpg" />
            </a>
          </Link>
        </BannerLi>
      </SidemenuUl>
    </SidemenuArea>
  );
};
const SidemenuArea = styled.div`
  /* width: 280px; */
  width: 30%;
  min-width: 200px;
  margin: 60px 0;
  margin-left: 2%;
`;
const SidemenuUl = styled.ul`
  list-style: none;
`;
const SidemenuLi = styled.li`
  height: 85px;
  background: #fff;
  //   border: 1px solid #b1b1b1;
  margin: 6px 0;
`;
const SidemenuNavA = styled.a`
  background: #daf1ff;
  display: block;
  font-size: 1.6rem;
  font-weight: bold;
  color: #5b5b5b;
  line-height: 83px;
  padding: 0 6%;
  text-decoration: none;
  position: relative;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;
const Button1 = styled.button`
  /* width: 280px; */
  width: 100%;
  height: 85px;
  background: #f27405;
  color: #fff;
  font-size: 1.6rem;
  font-weight: bold;
  border: none;
  margin: 6px auto;
  padding: 0 8%;
  text-align: left;
  position: relative;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;
const Button2 = styled.button`
  /* width: 280px; */
  width: 100%;
  height: 85px;
  background: #fff6ee;
  color: #f27405;
  font-size: 1.6rem;
  font-weight: bold;
  border: 1px solid #f27405;
  margin: 6px auto;
  padding: 0 8%;
  text-align: left;
  position: relative;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;
const Banner = styled.img`
  width: 100%;
`;
const BannerLi = styled.li`
  margin: 6px 0;
`;
export default Sidemenu;
