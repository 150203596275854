import React from 'react';
import styled, { css } from 'styled-components';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import media from 'styled-media-query';

const Footer = () => {
  return (
    <footer css="margin-top:50px;border-top:1px solid #B1B1B1;background: #daf1ff;padding: 30px 0;">
      <FooterInner>
        <img src="/images/logo.png" alt="" />
        <p>〒242-0018 神奈川県大和市深見西2丁目1-25</p>
        <p>TEL 046-263-0681</p>
        <div
          css={`
            text-align: center;
            margin-top: 25px;
          `}
        >
          <p>Copyright © Yamato Ayase Dental Assosiation All Rights Reserved.</p>
        </div>
      </FooterInner>
    </footer>
  );
};

const FooterInner = styled.div`
  width: 90%;
  max-width: 1150px;
  font-size: 1.4rem;
  margin: 0 auto;
`;

export default Footer;
